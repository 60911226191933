





















import { Component, Vue } from 'vue-property-decorator'
import { getInformationCollectNum } from '@/pages/index/api/share'
@Component
export default class extends Vue {
  baseConfig: any = {
    avatar_collect_num: {
      label: '头像',
      target: '用于完善游艺堂注册及使用社交等功能',
      scene: '用户主动上传、修改头像时',
      num: ''
    },
    nickname_collect_num: {
      label: '昵称',
      target: '用于完善游艺堂注册及使用社交等功能',
      scene: '用户主动提交、修改昵称时',
      num: ''
    },
    account_collect_num: {
      label: '账号',
      target: '用于完善游艺堂注册及使用社交等功能',
      scene: '用户主动修改账号时',
      num: ''
    },
    phone_collect_num: {
      label: '手机号',
      target: '用于完善游艺堂注册',
      scene: '用户首次注册、主动修改手机号时',
      num: ''
    },
    // sex_collect_num: {
    //   label: '性别',
    //   target: '用于完善游艺堂注册及使用社交等功能',
    //   scene: '用户主动选择、修改性别时',
    //   num: ''
    // },
    // birthday_collect_num: {
    //   label: '生日',
    //   target: '用于完善游艺堂注册及使用社交等功能',
    //   scene: '用户主动填写、修改生日时',
    //   num: ''
    // },
    realname_collect_num: {
      label: '姓名',
      target: '用于完成实名认证以使用创建房间等功能',
      scene: '用户主动填写时',
      num: ''
    },
    idcard_collect_num: {
      label: '身份证号',
      target: '用于完成实名认证以使用创建房间等功能',
      scene: '用户主动填写时',
      num: ''
    },
    // location_collect_num: {
    //   label: '位置',
    //   target: '用于丰富社交体验同时为用户提供基于位置信息的相关功能',
    //   scene: '用户启动App、发布动态时',
    //   num: ''
    // },
    dynamic_collect_num: {
      label: '图片',
      target: '用于用户修改头像等功能',
      scene: '用户修改头像',
      num: ''
    }
  }

  queryKey = ''
  init() {
    this.queryKey = this.$route.query.key as string
    this.getData()
  }
  async getData() {
    if (this.queryKey) {
      const params = { key: this.queryKey }
      const { data } = await getInformationCollectNum(params)
      this.baseConfig[this.queryKey].num = data.num
    }
  }
  mounted() {
    this.init()
  }
}
